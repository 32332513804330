<div class="protec8-app">
  @if (!hideNavbar()) {
    <div class="w-full px-2">
      <app-navbar></app-navbar>
    </div>
  }

  @defer {
    <div class="app-content">
      <router-outlet></router-outlet>
    </div>
    <o8-impersonation-widget></o8-impersonation-widget>
    <o8-enable-notification-banner></o8-enable-notification-banner>
  }
</div>
