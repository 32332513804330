import { Component } from '@angular/core';
import { IconModule, Origin8Icon } from '@origin8-web/core-ui/icon';
import { PROTEC8_PATH } from '../../app.interface';
import { RouterModule } from '@angular/router';
import { NavbarMenuDropdownComponent } from './navbar-menu-dropdown/navbar-menu-dropdown.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [RouterModule, IconModule, NavbarMenuDropdownComponent],
})
export class NavbarComponent {
  appName = 'Protect8';
  links = {
    dashboard: PROTEC8_PATH.DASHBOARD,
    warmTransfer: PROTEC8_PATH.WARM_TRANSFER,
    quotes: PROTEC8_PATH.QUOTES,
  };
  Origin8Icon = Origin8Icon.windows;
  menuIcon = Origin8Icon.menu;
  closeIcon = Origin8Icon.close;
  isMenuOpen = false;

  openMenu() {
    this.isMenuOpen = true;
  }
  closeMenu() {
    this.isMenuOpen = false;
  }
}
