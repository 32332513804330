<div class="navbar-menu-dropdown">
  <div class="menu-container">
    <div
      class="menu-item first-item"
      [routerLink]="links.dashboard"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active-navlink"
    >
      Home
    </div>
    @if (!userStore.isContactor()) {
      <div class="menu-item" (click)="openWtPage()">Open WT Popup</div>
      <div class="menu-item" [routerLink]="links.warmTransfer" routerLinkActive="active-navlink">Warm Transfer</div>
    }
    <div class="menu-item" [routerLink]="links.quotes" routerLinkActive="active-navlink">Quotes</div>
    <div class="menu-item" [routerLink]="links.releases" routerLinkActive="active-navlink">Releases</div>
    <div
      class="menu-item"
      [routerLink]="links.impersonation"
      routerLinkActive="active-navlink"
      *o8OnlyPermissions="impersonationPerms"
    >
      Impersonation
    </div>
    <div
      class="menu-item"
      [routerLink]="links.advocateScheduleAdherence"
      routerLinkActive="active-navlink"
      *o8OnlyPermissions="advocateScheduleAdherencePerms"
    >
      Advocate Schedule Adherence
    </div>
    <div
      class="menu-item"
      [routerLink]="links.allCallScripts"
      routerLinkActive="active-navlink"
      *o8OnlyPermissions="callScriptPerms"
    >
      Call Scripts
    </div>
    <div
      class="menu-item"
      [routerLink]="links.scorecard"
      routerLinkActive="active-navlink"
      *o8OnlyPermissions="scorecardPerms"
    >
      Scorecard
    </div>
    <div class="menu-item" [routerLink]="links.logout" routerLinkActive="active-navlink">Logout</div>
  </div>
</div>
