// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  pathToSource: 'browser',
  securedGraphQlGateway: 'https://gateway-private.dev.o8cares.com/federated-gql',
  securedApiGateway: 'https://gateway-private.dev.o8cares.com/api',
  anonymousGatewayApi: 'https://gateway-public.dev.o8cares.com/api',
  auth: { domain: 'auth.dev.o8cares.com', clientId: 'rYfk0A7un7bpeMbuB4k8hgKayVgbz7BL', audience: 'Permission API' },
  baseArcClientUrl: 'https://arc.dev.origin8cares.com',
  salesForceBaseUrl: 'https://origin8cares--uat.sandbox.lightning.force.com',
  trackingId: 'bvowph/protec8',
  gatewayStreamingUrl: 'https://gateway-streaming.dev.o8cares.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
