import { createReducer, on } from '@ngrx/store';
import { PrioritizedLeadRecordDetails } from 'common.interfaces';
import { TopPriorityWorkState } from '../../../app.interface';
import { priorityLeadsActions } from '../actions/priority-leads.actions';

const initialState: TopPriorityWorkState = {
  active: false,
  prioritizedLeads: [],
  loadingPrioritizedLeads: false,
  muted: false,
};

export const setPrioritizedLeads = (
  state: TopPriorityWorkState,
  { leadsDetails }: { leadsDetails: PrioritizedLeadRecordDetails[] },
): TopPriorityWorkState => {
  return { ...state, prioritizedLeads: leadsDetails };
};

export const setIsLoadingPrioritizedLeads = (
  state: TopPriorityWorkState,
  { loadingPrioritizedLeads }: { loadingPrioritizedLeads: boolean },
): TopPriorityWorkState => {
  return { ...state, loadingPrioritizedLeads };
};

export const setMuteState = (state: TopPriorityWorkState, { muted }: { muted: boolean }): TopPriorityWorkState => {
  return { ...state, muted };
};

export const setActiveState = (state: TopPriorityWorkState, { active }: { active: boolean }): TopPriorityWorkState => {
  if (!active) {
    return initialState;
  }
  return { ...state, active: true, muted: false };
};

export const priorityLeadsReducers = createReducer(
  initialState,
  on(priorityLeadsActions.setPrioritizedLeads, setPrioritizedLeads),
  on(priorityLeadsActions.setIsLoadingPrioritizedLeads, setIsLoadingPrioritizedLeads),
  on(priorityLeadsActions.setMuteState, setMuteState),
  on(priorityLeadsActions.setActiveState, setActiveState),
);
