import { Routes } from '@angular/router';
import { canActivateAuth } from './authentication.guard';
import { AUTH_PATH } from '../login.interface';

export const authRoutes: Routes = [
  {
    path: AUTH_PATH.REDIRECT,
    pathMatch: 'full',
    loadComponent: () => import('../components/redirect/redirect.component').then((m) => m.LoginRedirectComponent),
  },
  {
    path: AUTH_PATH.FAIL,
    pathMatch: 'full',
    loadComponent: () => import('../components/login-error/login-error.component').then((m) => m.LoginErrorComponent),
  },
  {
    path: AUTH_PATH.LOGIN,
    pathMatch: 'full',
    loadComponent: () =>
      import('../components/wait-for-login/wait-for-login.component').then((m) => m.WaitForLoginComponent),
  },
  {
    path: AUTH_PATH.LOGOUT,
    pathMatch: 'full',
    loadComponent: () => import('../components/logout/logout.component').then((m) => m.LogoutComponent),
  },
  {
    path: AUTH_PATH.IMPERSONATION,
    pathMatch: 'full',
    canActivate: [canActivateAuth],
    loadComponent: () =>
      import('../components/impersonation/page/impersonation.component').then((m) => m.ImpersonationComponent),
  },
];
